import React from 'react'
import { Box, Text } from 'rebass/styled-components'

import Section from './section'
import Container from './container'
import Cards from './cards'

const cards = [
  {
    html:
      'Trvale dodržovat příslušné právní požadavky a nařízení a správní rozhodnutí na ochranu životního prostředí.',
  },
  {
    html: `Šetrně zacházet s přírodními zdroji, zejména při používání materiálů, energií a při nakládání s vodami.`,
  },
  {
    html: `Minimalizovat negativní vlivy na životní prostředí při přípravě a realizaci investičních záměrů svých zákazníků.`,
  },
  {
    html: `Předcházet negativním vlivům na životní prostředí využíváním všech dostupných technických a organizačních prostředků.`,
  },
  {
    html: `Zvyšovat znalosti, dovednosti a povědomí zaměstnanců v oblasti péče o životní prostředí, předcházení negativních vlivů společnosti na životní prostředí a snižování rizik vzniku mimořádných nehod a havárií.`,
  },
  {
    html: `Otevřeně komunikovat s veřejností, orgány státní správy, zákazníky a zainteresovanými stranami z hlediska ochrany životního prostředí.`,
  },
  {
    html: `Vyžadovat od svých smluvních partnerů aktivní přístup k ochraně životního prostředí a tento přístup kontrolovat a vyhodnocovat.`,
  },
  {
    html: `Pravidelně prověřovat a vyhodnocovat systém environmentálního managementu: k tomu účelu jsou stanoveny environmentální cíle, kompetence a potřebné zdroje.`,
  },
]

const Environment = () => {
  return (
    <Section>
      <Container width="60rem">
        <Box>
          <Text variant="body" as="p">
            ENVIRONMENTÁLNÍ POLITIKA Czech heat service s.r.o. Vedení
            společnosti Czech heat service s.r.o. vyhlašuje tuto environmentální
            politiku:
          </Text>

          <Text variant="body" as="p">
            Společnost Czech heat service s.r.o. se sídlem v Na Lánech 58/21,
            Nová Ves, 709 00 Ostrava se zaměřuje na komplexní dodávky ve
            stavebnictví a tepelné technice pro objekty občanské a průmyslové
            výstavby.
          </Text>

          <Text variant="body" as="p">
            Vedení společnosti si uvědomuje, že všechny činnosti, které jsou
            spojeny se stavební výrobou a poskytováním služeb v oblasti občanské
            a průmyslové výstavby, mohou přímo nebo nepřímo působit na životní
            prostředí.
          </Text>

          <Text variant="body" as="p">
            Czech heat service s.r.o. klade důraz na ochranu životního prostředí
            při všech svých činnostech a formuluje tyto zásady své
            environmentální politiky:
          </Text>

          <Cards cards={cards} />

          <Text variant="body" as="p">
            Czech heat service s.r.o. se zavazuje k trvalému zlepšování vztahu
            společnosti k životnímu prostředí v mezích svých ekonomických
            zdrojů.
          </Text>

          <Text variant="body" as="p">
            V Ostravě 20.1.2022
          </Text>

          <Text variant="body" as="p">
            Ing. Bc. Martin Jaich - jednatel
          </Text>
        </Box>
      </Container>
    </Section>
  )
}

export default Environment
